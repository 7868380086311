import React from 'react'
import { FaClock, FaHandPointer, FaMobile } from 'react-icons/fa'
import LayoutTheme from '../components/layout/layout'
import Hero from '../components/hero/hero'
import HowItWork from '../components/howItWorks/howItWorks'
import Benefits from '../components/benefits/benefits'
import Chat from '../components/chat/chat'
import BuyerForm from '../components/buyerForm/buyerForm'
import About from '../components/about/about'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Buyer = ({ data }) => {
  const [benefits] = [data.datoCmsBuyerPage.benefits]
  benefits[0].icon = <FaMobile size={48} color={'#fba394'} />
  benefits[1].icon = <FaClock size={48} color={'#fba394'} />
  benefits[2].icon = <FaHandPointer size={48} color={'#fba394'} />
  return (
    <div>
      <HelmetDatoCms seo={data.datoCmsBuyerPage.seoMetaTags} />
      <div>
        <LayoutTheme headerTransparency={true}>
          <Hero wording={data.datoCmsBuyerPage.hero} />
          <div className="bg-primary row">
            <div className="col-12">
              <About title={data.datoCmsBuyerPage.aboutTitle} wording={data.datoCmsBuyerPage.aboutDescription} />
            </div>

            <div className="col-12">
              <HowItWork steps={data.datoCmsBuyerPage.howItWorkList} title={data.datoCmsBuyerPage.howItWork} />
            </div>
            <div className="col-12">
              <Benefits benefits={benefits}>
                <div id="chatForm"></div>
              </Benefits>
            </div>
            <div className="col-12">
              <Chat title={data.datoCmsBuyerPage.chatTitle} description={data.datoCmsBuyerPage.chatDescription}>
                <BuyerForm />
              </Chat>
            </div>
          </div>
        </LayoutTheme>
      </div>
    </div>
  )
}

export default Buyer

export const query = graphql`
  query BuyerQuery {
    datoCmsBuyerPage {
      hero
      aboutTitle
      aboutDescription
      howItWork
      howItWorkList {
        id
        title
        description
      }
      benefit
      benefits {
        id
        title
        description
      }
      chatTitle
      chatDescription
      seoMetaTags {
        tags
      }
    }
  }
`
